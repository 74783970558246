import util from "@bmp-vue/core/api/util";

const controller = "Gen0001";
export default Object.assign({}, util.GetCrud(controller, [""]), {
    Incluir: function(dto) {
        return util.Axios.Post(
            controller + "/eco",
            dto
        );
    },
    IncluirArquivo: function(dto) {
        return util.Axios.Post(
            controller + "/eco/Importar",
            dto
        );
    },
    GetRespostaSincronaByCodigo: function(codigo) {
        return util.Axios.GetAll(controller + `/${codigo}/RespostaSincrona`)
    },
});