import util from '@bmp-vue/core/api/util'

const controller = 'ParceiroApiKey'
export default Object.assign({}, util.GetCrud(controller, null), {
    Desativar: function (codigoParceiro, motivo) {
        return util.Axios.Put(
            `${controller}/${codigoParceiro}/desativar`,
            '',
            { codigo: codigoParceiro, motivo }
        );
    }
})
