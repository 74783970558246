import util from '@bmp-vue/core/api/util'

const controller = 'Parceiros'
export default Object.assign({}, util.GetCrud(controller, null), {
    GetAllApiKeysByCodigo: function (codigoParceiro) {
        return util.Axios.GetAll(`${controller}/${codigoParceiro}/ApiKeys`)
    },
    GerarApiKey: function (codigoParceiro) {
        return util.Axios.Post(`${controller}/${codigoParceiro}/ApiKey/Gerar`,
        { codigo: codigoParceiro })
    }
})
